<style scoped lang="scss">
  .online_data_box {
    .toolbars {
      .otherbar {
        float: right;
        .selectservicer,.searchbtn  {
          display: inline-block;
        }
      }
    }
    .clearb {
      margin-bottom: 15px;
    }
  }
</style>

<template>
  <div class="online_data_box">
    <div class="toolbars">
      <div class="otherbar">
        <date-time @commitPicker="getPicker" class="mrgr5 mrgb5"></date-time>
        <el-button class="searchbtn mrgb5" type="primary" plain size="small" @click="_search"><i class="fa fa-search"></i> 查询</el-button>
      </div>
    </div>
    <div class="clearb"></div>
    <echart id="onlineEchart" title="在线人数统计" width="100%" height="80vh" :option="options"></echart>
  </div>
</template>

<script>
import dateTime from "@/components/DateTime"
import echart from "@/components/Echarts/Index";
export default {
  name: "online",
  components: {
    dateTime,
    echart
  },
  data() {
    return {
      options: {},
      listQuery: {
        serverId: null,
        beginTime: null,
        endTime: null
      }
    };
  },
  mounted() {
    this.setOptions();
  },
  methods: {
    setOptions() {
      let xAxisData = []
      let onlineData = []
      for (let i = 0; i < 24; i++) {
        let time = "20日\n" + (i < 10 ? "0" + i : i) + "点"
        let item = Math.floor(Math.random() * 10000)
        xAxisData.push(time)
        onlineData.push(item)
      }
      this.options = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["在线人数"]
        },
        grid: {
          containLabel:true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        dataZoom: {
          show: true,
          start: 0,
          end: 100
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: xAxisData
        },
        yAxis: {
          type: "value",
          axisTick: {

          },
          axisLabel: {
            formatter: "{value}"
          }
        },
        series: [
          {
            name: "在线人数",
            type: "bar",
            stack: "total",
            barWidth: "20%",
            barMaxWidth: "20px",
            data: onlineData
          }
        ]
      }
    },
    getPicker(val) {
      this.listQuery.beginTime = val[0]
      this.listQuery.endTime = val[1]
    },
    _search() {
      
    }
  }
};
</script>